import React, { createContext, useState, useContext } from 'react';

// Create Context
const CartContext = createContext();

export const useCart = () => useContext(CartContext);

// Context Provider Component
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => JSON.parse(localStorage.getItem('cart')) || []);
  const [totalPrice, setTotalPrice] = useState(() => parseFloat(localStorage.getItem('totalPrice')) || 0);

  const addToCart = (product) => {
    setCart((prevCart) => {
      const updatedCart = [...prevCart, product];
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    });
    setTotalPrice((prevTotal) => {
      const newTotal = prevTotal + product.price;
      localStorage.setItem('totalPrice', newTotal.toString());
      return newTotal;
    });
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter((item) => item.id !== productId);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    });
    setTotalPrice((prevTotal) => {
      const removedProduct = cart.find((item) => item.id === productId);
      const newTotal = removedProduct ? prevTotal - removedProduct.price : prevTotal;
      localStorage.setItem('totalPrice', newTotal.toString());
      return newTotal;
    });
  };

  const resetCart = () => {
    setCart([]);
    setTotalPrice(0);
    localStorage.removeItem('cart');
    localStorage.removeItem('totalPrice');
  };

    const cartItemCount = cart.length;
  return (
    <CartContext.Provider value={{ cart, totalPrice, addToCart, removeFromCart, resetCart, cartItemCount }}>
      {children}
    </CartContext.Provider>
  );
};
