import React, { useState, useEffect, useRef } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { keyframes } from '@emotion/react';

// Define keyframes for the bounce effect (existing)
const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Define keyframes for the materialize effect (new)
const materialize = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px); /* Start slightly below */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End in the original position */
  }
`;

const CustomCard = ({
  title,
  description,
  mediaUrl,
  mediaType,
  split,
  customMediaComponent,
  invert = false, // New prop to control the inversion of sections
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null); // Ref to the card element

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true); // Set visible when in viewport
            observer.unobserve(entry.target); // Stop observing once visible
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the card is in view
    );

    if (cardRef.current) {
      observer.observe(cardRef.current); // Observe the card element
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current); // Cleanup on component unmount
      }
    };
  }, []);

  const textWidth = Math.min(Math.max(split, 0), 100);
  const mediaWidth = 100 - textWidth;

  // Decide the order of text and media sections based on `invert` prop
  const textSection = (
    <Grid
      item
      xs={12}
      md={(textWidth / 100) * 12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0",
        boxSizing: 'border-box',
      }}
    >
      <CardContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        {typeof description === "function" ? description() : <Typography variant="body1">{description}</Typography>}
      </CardContent>
    </Grid>
  );

  const mediaSection = (
    <Grid
      item
      xs={12}
      md={(mediaWidth / 100) * 12}
      sx={{
        height: "100%",
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden', // Ensure the content does not overflow
      }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        {customMediaComponent ? (
          <div style={{ width: '100%', height: '100%', objectFit: 'contain' }}>
            {customMediaComponent}
          </div>
        ) : mediaType === 'video' ? (
          <video
            src={mediaUrl}
            controls
            style={{ width: "100%", height: "100%", objectFit: "contain" }} // Ensure video scales properly
          />
        ) : (
          <img
            src={mediaUrl}
            alt={title}
            style={{ width: "100%", height: "100%", objectFit: "contain" }} // Ensure image scales properly
          />
        )}
      </div>
    </Grid>
  );


  return (
    <Card
      ref={cardRef} // Attach ref to the card element
      sx={{
        width: "calc(100% - 20px)",
        maxWidth: "100vw",
        height: "calc(100vh - 20px)",
        maxHeight: "100vh",
        display: "flex",
        margin: "10px",
        boxSizing: 'border-box',
        overflow: 'hidden',
        backgroundColor: '#E7E1CC',
        transition: 'transform 0.3s ease', // Smooth transition for hover effect
        opacity: 0, // Start with invisible
        animation: isVisible ? `${materialize} 1s ease forwards` : "none", // Apply materialize animation when visible
        boxShadow: 'none',
        border: 'none',
        '&:hover': {
          transform: 'translateY(-10px)', // Directly apply transform for hover effect
        },
      }}
    >
      <Grid container sx={{ height: "100%" }}>
        {invert ? (
          <>
            {mediaSection} {/* Media first if invert is true */}
            {textSection}
          </>
        ) : (
          <>
            {textSection} {/* Text first if invert is false */}
            {mediaSection}
          </>
        )}
      </Grid>
    </Card>
  );
};

export default CustomCard;
