import React from 'react';
import { Container, Typography, Button, Box, Grid, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCart } from './CartContext';
import SignIn from './SignIn.js';
import CustomButton from './CustomButton.js';
const Cart = () => {
  const navigate = useNavigate();
  const { cart, totalPrice, removeFromCart, resetCart } = useCart();


  React.useEffect(() => {
    if (totalPrice !== undefined) {
      localStorage.setItem('totalPrice', totalPrice.toString());
    }
  }, [totalPrice]);

  const handleRemoveProduct = (productId) => {
    removeFromCart(productId);
  };

  const handleResetCart = () => {
    resetCart();
  };

  if (cart.length === 0) {
    return (
      <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh' }}>
        <Grid item xs={12} sx={{ position: 'relative', top: '-220px', left: '-20px' }}>
          <CustomButton text="Back to Product Catalog" link="/productList" icon=<ArrowBackIcon/>/>

        </Grid>
        <Typography variant="h4" align="center" sx={{ mt: 5 }}>
          Your Cart is Empty
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', py: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 5 }}>
        <Typography variant="h4">Your Cart</Typography>
        <CustomButton text="Checkout" link="/Checkout"/>
      </Box>
      <Grid container spacing={4} sx={{ flexGrow: 1 }}>
        {cart.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardActionArea component={RouterLink} to={`/product/${product.id}`}>
                <CardMedia
                  component="img"
                  image={product.imageUrl} // Ensure this is a valid URL
                  alt={product.name}
                  sx={{ height: 200 }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {product.name}
                  </Typography>
                  <Typography>£{product.price}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleRemoveProduct(product.id)}
                >
                  Remove
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 5, mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Total Price: £{totalPrice ? totalPrice.toFixed(2) : '0.00'}
        </Typography>
        <CustomButton text="Reset Cart" onClick={handleResetCart} icon=<ClearAllIcon/>/>
      </Box>
    </Container>
  );
};

export default Cart;
