import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useCart } from './CartContext';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { cart, totalPrice, resetCart } = useCart();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [address, setAddress] = useState({});

  const handleAddressChange = (event) => {
    if (event.complete) {
      setAddress(event.value);
    } else {
      setAddress({});
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submit event triggered'); // Log to verify submission

    if (!stripe || !elements) {
      console.error('Stripe or Elements not initialized');
      return;
    }

    setIsProcessing(true);
    setError(null);
    console.log("getting past the if statement")

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: 'https://eggccessories.com/complete',
          shipping: {
            address: {
              line1: address.line1,
              city: address.city,
              state: address.state,
              postal_code: address.postal_code,
              country: address.country,
            },
          },
        },
        redirect: 'if_required',
      });

      console.log('Payment response:', { error, paymentIntent }); // Log payment response

      if (error) {
        setError(error.message);
        setIsProcessing(false);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {

        console.log('Payment succeeded, sending order completion request'); // Log payment success

        const orderDetails = {
            paymentId: paymentIntent.id,
            email: user.email,
            userName: user.displayName,
            address: address,
            totalCost: totalPrice,
            cartItems: cart
        }
        try {
          const response = await fetch('https://eggccessories.com/complete-order', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderDetails),
          });

          const responseText = await response.text();
          console.log('Fetch response:', responseText); // Log fetch response

          if (!response.ok) {
            console.error('Failed to complete order: ${responseText}');
          } else {
            console.log('Order completion request successful');
            for (const item of cart) {
                        try {
                          const deleteResponse = await fetch(`http://localhost:8080/cart/${item.id}`, {
                            method: 'DELETE',
                          });

                          if (!deleteResponse.ok) {
                            console.error(`Failed to delete item with ID ${item.id}`);
                          } else {
                            console.log(`Item with ID ${item.id} deleted successfully`);
                          }
                        } catch (deleteError) {
                          console.error(`Error deleting item with ID ${item.id}:`, deleteError);
                        }
                      }
            resetCart();
            navigate(`/complete?payment_intent=${paymentIntent.id}&payment_intent_client_secret=${paymentIntent.client_secret}&redirect_status=succeeded`);
          }
        } catch (fetchError) {
          console.error('Error sending order completion request:', fetchError);
        }
      } else {
        console.warn('Payment Intent not successful or status unknown');
      }
    } catch (error) {
      console.error('Error during payment processing:', error);
      setError('An unexpected error occurred. Please try again.');
      setIsProcessing(false);
    } finally {
      setIsProcessing(false);
    }
  };


  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 600 }}>
      <PaymentElement />
      <AddressElement options={{ mode: 'shipping' }} onChange={handleAddressChange} />
      {error && (
        <Typography color="error" variant="body1" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{
          mt: 4,
          backgroundColor: "#36A49A",
          "&:hover": {
            backgroundColor: '#2f8f87',
          },
        }}
        disabled={isProcessing || succeeded}
      >
        {isProcessing ? <CircularProgress size={24} color="inherit" /> : 'Pay Now'}
      </Button>
    </Box>
  );
};

export default CheckoutForm;




//import React, { useState } from 'react';
//import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
//import { Button, Typography, Box, CircularProgress, TextField, FormControlLabel, Checkbox } from '@mui/material';
//import { useNavigate } from 'react-router-dom';
//import { useAuth } from './AuthContext';
//import { useCart } from './CartContext';
//
//const CheckoutForm = () => {
//  const stripe = useStripe();
//  const elements = useElements();
//  const navigate = useNavigate();
//  const { user } = useAuth();
//  const { cart, totalPrice, resetCart } = useCart();
//  const [error, setError] = useState(null);
//  const [isProcessing, setIsProcessing] = useState(false);
//  const [succeeded, setSucceeded] = useState(false);
//
//  // Shipping address state
//  const [shippingAddress, setShippingAddress] = useState({
//    name: '', // Add name field
//    line1: '',
//    line2: '',
//    city: '',
//    state: '',
//    postal_code: '',
//    country: '',
//  });
//
//  // Billing address state
//  const [billingAddress, setBillingAddress] = useState({
//    line1: '',
//    line2: '',
//    city: '',
//    state: '',
//    postal_code: '',
//    country: '',
//  });
//
//  // Toggle between same or different addresses
//  const [useDifferentBillingAddress, setUseDifferentBillingAddress] = useState(false);
//
//  // Handle address changes
//  const handleAddressChange = (e, type) => {
//    const { name, value } = e.target;
//    if (type === 'shipping') {
//      setShippingAddress(prev => ({ ...prev, [name]: value }));
//    } else if (type === 'billing') {
//      setBillingAddress(prev => ({ ...prev, [name]: value }));
//    }
//  };
//
//  const handleSubmit = async (event) => {
//    event.preventDefault();
//    console.log('Form submit event triggered');
//
//    if (!stripe || !elements) {
//      console.error('Stripe or Elements not initialized');
//      return;
//    }
//
//    setIsProcessing(true);
//    setError(null);
//
//    try {
//      // Set the shipping and billing addresses
//      const shippingDetails = {
//        name: shippingAddress.name,
//        address: {
//          line1: shippingAddress.line1,
//          line2: shippingAddress.line2,
//          city: shippingAddress.city,
//          state: shippingAddress.state,
//          postal_code: shippingAddress.postal_code,
//          country: shippingAddress.country,
//        },
//      };
//
//      const billingDetails = useDifferentBillingAddress ? {
//        address: {
//          line1: billingAddress.line1,
//          line2: billingAddress.line2,
//          city: billingAddress.city,
//          state: billingAddress.state,
//          postal_code: billingAddress.postal_code,
//          country: billingAddress.country,
//        },
//      } : null;
//
//      const { error, paymentIntent } = await stripe.confirmPayment({
//        elements,
//        confirmParams: {
//          return_url: 'http://localhost:3000/complete',
//          shipping: shippingDetails,
//          billing: billingDetails, // Include billing details if specified
//        },
//        redirect: 'if_required',
//      });
//
//      console.log('Payment response:', { error, paymentIntent });
//
//      if (error) {
//        setError(error.message);
//        setIsProcessing(false);
//      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
//        console.log('Payment succeeded, sending order completion request');
//
//        const orderDetails = {
//          paymentId: paymentIntent.id,
//          email: user.email,
//          userName: user.displayName,
//          shippingAddress,
//          billingAddress: useDifferentBillingAddress ? billingAddress : shippingAddress,
//          totalCost: totalPrice
//        }
//
//        try {
//          const response = await fetch('http://localhost:8080/complete-order', {
//            method: 'POST',
//            headers: {
//              'Content-Type': 'application/json',
//            },
//            body: JSON.stringify(orderDetails),
//          });
//
//          const responseText = await response.text();
//          console.log('Fetch response:', responseText);
//
//          if (!response.ok) {
//            console.error(`Failed to complete order: ${responseText}`);
//          } else {
//            console.log('Order completion request successful');
//            resetCart();
//            navigate(`/complete?payment_intent=${paymentIntent.id}&payment_intent_client_secret=${paymentIntent.client_secret}&redirect_status=succeeded`);
//          }
//        } catch (fetchError) {
//          console.error('Error sending order completion request:', fetchError);
//        }
//      } else {
//        console.warn('Payment Intent not successful or status unknown');
//      }
//    } catch (error) {
//      console.error('Error during payment processing:', error);
//      setError('An unexpected error occurred. Please try again.');
//      setIsProcessing(false);
//    } finally {
//      setIsProcessing(false);
//    }
//  };
//
//  return (
//    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 600 }}>
//      <PaymentElement />
//
//      <Typography variant="h6" gutterBottom>Shipping Address</Typography>
//      <TextField
//        name="name"
//        label="Name"
//        value={shippingAddress.name}
//        onChange={(e) => handleAddressChange(e, 'shipping')}
//        fullWidth
//        margin="normal"
//      />
//      <TextField
//        name="line1"
//        label="Address Line 1"
//        value={shippingAddress.line1}
//        onChange={(e) => handleAddressChange(e, 'shipping')}
//        fullWidth
//        margin="normal"
//      />
//      <TextField
//        name="line2"
//        label="Address Line 2 (optional)"
//        value={shippingAddress.line2}
//        onChange={(e) => handleAddressChange(e, 'shipping')}
//        fullWidth
//        margin="normal"
//      />
//      <TextField
//        name="city"
//        label="City"
//        value={shippingAddress.city}
//        onChange={(e) => handleAddressChange(e, 'shipping')}
//        fullWidth
//        margin="normal"
//      />
//      <TextField
//        name="state"
//        label="State"
//        value={shippingAddress.state}
//        onChange={(e) => handleAddressChange(e, 'shipping')}
//        fullWidth
//        margin="normal"
//      />
//      <TextField
//        name="postal_code"
//        label="Postal Code"
//        value={shippingAddress.postal_code}
//        onChange={(e) => handleAddressChange(e, 'shipping')}
//        fullWidth
//        margin="normal"
//      />
//      <TextField
//        name="country"
//        label="Country"
//        value={shippingAddress.country}
//        onChange={(e) => handleAddressChange(e, 'shipping')}
//        fullWidth
//        margin="normal"
//      />
//
//      <FormControlLabel
//        control={
//          <Checkbox
//            checked={useDifferentBillingAddress}
//            onChange={() => setUseDifferentBillingAddress(!useDifferentBillingAddress)}
//          />
//        }
//        label="Billing address is different from shipping address"
//      />
//
//      {useDifferentBillingAddress && (
//        <>
//          <Typography variant="h6" gutterBottom>Billing Address</Typography>
//          <TextField
//            name="line1"
//            label="Address Line 1"
//            value={billingAddress.line1}
//            onChange={(e) => handleAddressChange(e, 'billing')}
//            fullWidth
//            margin="normal"
//          />
//          <TextField
//            name="line2"
//            label="Address Line 2 (optional)"
//            value={billingAddress.line2}
//            onChange={(e) => handleAddressChange(e, 'billing')}
//            fullWidth
//            margin="normal"
//          />
//          <TextField
//            name="city"
//            label="City"
//            value={billingAddress.city}
//            onChange={(e) => handleAddressChange(e, 'billing')}
//            fullWidth
//            margin="normal"
//          />
//          <TextField
//            name="state"
//            label="State"
//            value={billingAddress.state}
//            onChange={(e) => handleAddressChange(e, 'billing')}
//            fullWidth
//            margin="normal"
//          />
//          <TextField
//            name="postal_code"
//            label="Postal Code"
//            value={billingAddress.postal_code}
//            onChange={(e) => handleAddressChange(e, 'billing')}
//            fullWidth
//            margin="normal"
//          />
//          <TextField
//            name="country"
//            label="Country"
//            value={billingAddress.country}
//            onChange={(e) => handleAddressChange(e, 'billing')}
//            fullWidth
//            margin="normal"
//          />
//        </>
//      )}
//
//      {error && (
//        <Typography color="error" variant="body1" sx={{ mt: 2 }}>
//          {error}
//        </Typography>
//      )}
//      <Button
//        type="submit"
//        variant="contained"
//        color="primary"
//        sx={{
//          mt: 4,
//          backgroundColor: "#36A49A",
//          "&:hover": {
//            backgroundColor: '#2f8f87',
//          },
//        }}
//        disabled={isProcessing || succeeded}
//      >
//        {isProcessing ? <CircularProgress size={24} color="inherit" /> : 'Pay Now'}
//      </Button>
//    </Box>
//  );
//};
//
//export default CheckoutForm;

