import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PictureRotator from './PictureRotator.js';

const PictureRotation = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('PictureRotation mounted');
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://eggccessories.com/api/products');
      console.log('Fetched products:', response.data);
      setImageUrls(response.data.map((product) => product.imageUrl));
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false); // Ensure loading is set to false in both success and error cases
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {/* Ensure PictureRotator fills the entire container */}
      {!loading && imageUrls.length > 0 && <PictureRotator imageUrls={imageUrls} />}
    </div>
  );
};

export default PictureRotation;
