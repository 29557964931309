import React from 'react';
import { createContext, useState, useEffect, useContext } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInAnonymously,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  sendEmailVerification as firebaseSendEmailVerification
} from 'firebase/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const auth = getAuth();


  const sendPasswordResetEmail = (email) => {
    const auth = getAuth();
    return firebaseSendPasswordResetEmail(auth, email);
  };


  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user);
          setIsEmailVerified(user.emailVerified);
        } else {
          setUser(null);
          setIsEmailVerified(false);
        }
      });
      return () => unsubscribe();
    }, [auth]);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);  // Ensure the promise is returned
  };

  const signInAnonymouslyHandler = () => {
    return signInAnonymously(auth);  // Return the promise
  };

  const signInWithEmail = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);  // Return the promise
  };

   const signUpWithEmail = async (email, password) => {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await firebaseSendEmailVerification(user);
        return user;
      } catch (error) {
        throw new Error(error.message);
      }
    };

  const signOut = () => {
    return firebaseSignOut(auth);  // Return the promise
  };

  return (
    <AuthContext.Provider value={{ user, signInWithGoogle, signInAnonymouslyHandler, signInWithEmail, signUpWithEmail, signOut, sendPasswordResetEmail}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
