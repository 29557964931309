import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import CustomButton from './CustomButton';
import { toast } from 'react-toastify';

// Complete list of country codes (simplified example)
const countryCodes = [
  { code: '+1', country: 'United States' },
  { code: '+44', country: 'United Kingdom' },
  { code: '+91', country: 'India' },
  { code: '+33', country: 'France' },
  { code: '+49', country: 'Germany' },
  { code: '+81', country: 'Japan' },
  { code: '+55', country: 'Brazil' },
  { code: '+86', country: 'China' },
  // Add more country codes as needed
];

const StylishForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "+1",
    comments: "",
    photo: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value
    });
  };

  const handleCountryCodeChange = (e) => {
    setFormData({
      ...formData,
      countryCode: e.target.value
    });
  };

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!formData.name || !formData.description) {
            alert("Name and description are required.");
            return;
          }

          // Submit the form data
          const formDataObj = new FormData();
          Object.keys(formData).forEach((key) => {
            formDataObj.append(key, formData[key]);
          });

          fetch("https://eggccessories.com/api/contact/submit", {
            method: "POST",
            body: formDataObj,
          })
            .then((response) => response.json())
            .then((data) => {
              toast.success("Form sent successfully!")
              console.log("Success:", data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Contact Us / Leave a Review
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
          required
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Email Address"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <FormControl variant="outlined" sx={{ mr: 1, minWidth: 120 }}>
            <InputLabel>Country Code</InputLabel>
            <Select
              value={formData.countryCode}
              onChange={handleCountryCodeChange}
              label="Country Code"
              name="countryCode"
            >
              {countryCodes.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.code} ({option.country})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            margin="normal"

            variant="outlined"
          />
        </Box>
        <TextField
          fullWidth
          label="Description"
          name="description"
          multiline
          rows={4}
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          required
          variant="outlined"
        />
        <Button
          variant="outlined"
          component="label"
          fullWidth
          sx={{ mt: 2 }}
        >
          Upload Photo
          <input
            type="file"
            name="photo"
            onChange={handleChange}
            hidden
          />
          <AttachFile />
        </Button>
        <TextField
          fullWidth
          label="Notes"
          name="notes"
          multiline
          rows={3}
          value={formData.notes}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        />
        <CustomButton text="Submit" onClick={handleSubmit}/>
      </Box>
    </Container>
  );
};

export default StylishForm;



