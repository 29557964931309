import React, { useState, useEffect, useRef } from 'react';

const PictureRotator = ({ imageUrls }) => {
  const [currentImage, setCurrentImage] = useState(imageUrls.length > 0 ? imageUrls[0] : '');
  const intervalRef = useRef(null);
  const imageIndex = useRef(0);

  useEffect(() => {
    if (!imageUrls || imageUrls.length === 0) return;

    const changeImage = () => {
      imageIndex.current = (imageIndex.current + 1) % imageUrls.length;
      setCurrentImage(imageUrls[imageIndex.current]);
    };

    if (currentImage !== imageUrls[0]) {
      setCurrentImage(imageUrls[0]);
    }

    intervalRef.current = setInterval(changeImage, 10000); // Change every 10 seconds

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [imageUrls]);

  if (!currentImage) return null;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundSize: 'contain', // Use 'contain' to maintain aspect ratio
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', // Ensure the image doesn't repeat
        backgroundImage: `url(${currentImage})`,
        transition: 'background-image 1s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />
  );
};


export default PictureRotator;
