import React from "react";

const Banner = ({ text, height = "300px", mediaType, mediaUrl, backgroundColor = "#ffffff", textColor = "#000000" }) => {
  return (
    <div
      style={{
        width: "100%",
        height: height, // Allow adjustable height
        backgroundColor: backgroundColor, // Adjustable background color
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
      }}
    >
      {mediaType === "image" && mediaUrl ? (
        <img
          src={mediaUrl}
          alt="Banner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures the image covers the entire banner
          }}
        />
      ) : mediaType === "video" && mediaUrl ? (
        <video
          src={mediaUrl}
          controls
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures the video covers the entire banner
          }}
        />
      ) : text ? (
        <div
          style={{
            color: textColor, // Adjustable text color
            fontSize: "2rem", // Adjust as needed
            zIndex: 1, // Ensure text is on top
            padding: "0 20px", // Prevent text from touching edges
          }}
        >
          {text}
        </div>
      ) : null}
    </div>
  );
};

export default Banner;
