import React from "react";
import { Button as MUIButton, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CustomButton = ({ text, link, onClick, icon }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <MUIButton
        component={link ? (link.startsWith('http') ? 'a' : RouterLink) : 'button'} // Use 'a' for external links, RouterLink for internal, 'button' otherwise
        href={link && link.startsWith('http') ? link : undefined} // Use href for external links
        to={link && !link.startsWith('http') ? link : undefined} // Use `to` for internal links
        onClick={onClick} // onClick can be defined for all cases
        variant="contained"
        sx={{
          backgroundColor: "#CBBF94",
          "&:hover": {
            backgroundColor: '#AA852D',
          },
        }}
        target={link && link.startsWith('http') ? "_blank" : undefined} // Open in new tab if external link
        rel={link && link.startsWith('http') ? "noopener noreferrer" : undefined} // Secure external link
        startIcon={icon ? icon : undefined} // Render icon if provided
      >
        {text}
      </MUIButton>
    </Box>
  );
};

export default CustomButton;
