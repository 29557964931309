import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Alert } from '@mui/material';
import { useAuth } from './AuthContext';

const ResetPasswordPage = () => {
  const { sendPasswordResetEmail } = useAuth();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleResetPassword = async () => {
    setMessage(null);
    setError(null);
    try {
      await sendPasswordResetEmail(email);
      setMessage('Password reset email sent!');
    } catch (err) {
      setError('Failed to send password reset email.');
    }
  };

  return (
    <Container component="main" maxWidth="xs" >
      <Typography variant="h5" align="center" gutterBottom>
        Reset Password
      </Typography>
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Email Address"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleResetPassword}
        sx={{ mt: 2 }}
      >
        Send Password Reset Email
      </Button>
    </Container>
  );
};

export default ResetPasswordPage;
