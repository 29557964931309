import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Box, Button, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import ownerImage from './Images/Eggccessories_logo.png'; // Replace with your image path
import CustomCard from './CustomCard.js';
import PictureRotation from './PictureRotation';
import Banner from './Banner';


function Owner() {

  return (
  <div>

    <CustomCard title="test" description="description" customMediaComponent={<PictureRotation/>} split = {40} invert={true} />
    <Banner text="Egi at Work" description="Egi crocheting a hat at 10x speed" height = '100px' backgroundColor = "#E7E1CC" textColor= "#AA852D" />
    <Banner mediaUrl="https://www.w3schools.com/html/mov_bbb.mp4" mediaType="video" height = '600px'  />

    </div>
  );
}

export default Owner;
