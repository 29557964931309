import React from "react";
import CustomCard from './CustomCard.js';
import ownerImage from './Images/Eggccessories-logo.png';
import { Card, Container, Box, Button, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import Banner from './Banner.js';
import CustomButton from './CustomButton.js';
const About = () => {


    const description = () => {
        return(
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <CardContent>
                  <Typography variant="h3" gutterBottom>
                    Meet the Owner!
                  </Typography>
                  <Typography variant="h5" paragraph>
                    This is Egg/Egi, the creator of whatever it is we sell. Please take a look at my Instagram page!
                  </Typography>
                    <CustomButton text="@Eggccessories__" link="https://www.instagram.com/eggccessories__/?igsh=bWEwcGFmY2c0cmRr&utm_source=qr"/>
                </CardContent>
              </Grid>
            </Grid>
    );
    };
  return (
  <div>
    <CustomCard title = "Welcome to Eggccessories!"
    description = {description}
    mediaUrl = {ownerImage}
    mediaType = "image"
    split = { 60 } />
  </div>
  );
};

export default About;
