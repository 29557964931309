import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDSgXQRREY8KfU0osVmGWGakUJL00U9fV0",
    authDomain: "eggccessories.firebaseapp.com",
    projectId: "eggccessories",
    storageBucket: "eggccessories.appspot.com",
    messagingSenderId: "150293613871",
    appId: "1:150293613871:web:0f0c1836bb5aebb2f5e5da",
    measurementId: "G-DFVC6TGYN1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
