import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';


import Header from './Header.js';
import Footer from './Footer.js';
import About from './About.js';
import PictureRotation from './PictureRotation.js';
import Owner from './Owner.js';
import ProductList from './ProductList.js';
import ProductDetail from './ProductDetail.js';
import Cart from './Cart.js';
import StylishForm from './StylishForm.js';
import Checkout from './Checkout.js';
import logo from './Images/Eggccessories_logo.png';
import { CartProvider } from './CartContext';
import { AuthProvider } from './AuthContext';
import Completion from './Completion.js';
import SignInOptions from './SignInOptions.js';
import ResetPasswordPage from './ResetPasswordPage.js';

const test_pk = "pk_test_51PqgNQLrmUg65mhaYvsJ04Sl2fuWvaIseXTwLBQTj5EACkqQwJdefpr9haMEc5bBvUIZ77tvHDidXCGxrYrPWd0V006WxpHH59";
const live_pk = "pk_live_51PqgNQLrmUg65mhaDgQnfa8BvJ1bceGFYOqnUl0Aa3dHlLuFLrjJmQ0zH2sGXKQuBLBpPypGMjtXQ7a0tW6rwOGw00OU4UMv44";
const stripePromise = loadStripe(test_pk);

function App() {
  return (
  <AuthProvider>
  <CartProvider>
    <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh', // Full viewport height
              }}
            >
      <ToastContainer/>
      <Router>
        <Header />

        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={
              <>
                <Helmet>
                    <title>Eggccessories | Handcrafted Accessories by Egi</title>
                    <meta name="description" content="Explore our growing collection of accessories each hand crafted by 아기 (Egi)" />
                    <meta property="og:title" content="Eggccessories | Handcrafted Accessories by Egi" />
                    <meta property="og:image" content={logo} />
                    <meta property="og:url" content="https://eggccessories.com" />
                    <meta property="og:type" content="website" />


                    <link rel="canonical" href="https://eggccessories.com"/>

                </Helmet>

                <About />
                <Owner />

              </>
            } />
            <Route path="/productList" element={
            <>
                <Helmet>
                    <link rel="canonical" href="https://eggccessories.com/productList" />
                </Helmet>
            <ProductList />
            </>
            } />
            <Route path="/product/:id" element={
            <>
            <Helmet>
                <link rel="canonical" href={"https://eggccessories.com/product/${window.location.pathname.split("/").pop()}"} />
            </Helmet>
            <ProductDetail />
            </>
            } />
            <Route path="/Inquiry" element={
            <>
                <Helmet>
                    <link rel="canonical" href="https://eggccessories.com/Inquiry" />
                </Helmet>
            <StylishForm />
            </>
            } />
            <Route path="/Checkout" element={
                <>
                   <Helmet>
                        <link rel="canonical" href="https://eggccessories.com/Checkout" />
                   </Helmet>
                   <Checkout />
                </>
            } />
                <Route path="/Cart" element={
                <>
                   <Helmet>
                        <link rel="canonical" href="https://eggccessories.com/Cart" />
                   </Helmet>
                   <Cart />
                </>
            } />
            <Route path="/complete" element={
                            <>
                               <Helmet>
                                    <link rel="canonical" href="https://eggccessories.com/complete" />
                               </Helmet>
                               <Completion />
                            </>
                        } />
            <Route path="/SignInOptions" element={
                            <>
                               <Helmet>
                                    <link rel="canonical" href="https://eggccessories.com/SignInOptions" />
                               </Helmet>
                               <SignInOptions />
                            </>
                        } />
            <Route path="/PasswordReset" element={
                            <>
                               <Helmet>
                                    <link rel="canonical" href="https://eggccessories.com/PasswordReset" />
                               </Helmet>
                               <ResetPasswordPage />
                            </>
                        } />
          </Routes>
          </Elements>
        </Box>

        <Footer />
      </Router>
    </Box>
    </CartProvider>
    </AuthProvider>
  );
}

export default App;




