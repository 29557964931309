import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Badge, Tooltip, Menu, MenuItem, Box } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logo from './Images/Eggccessories-logo.png';
import { ShoppingCart, AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import { useCart } from './CartContext';
import { useAuth } from './AuthContext';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { cartItemCount } = useCart();
  const [anchorEl, setAnchorEl] = useState(null);
  const { signIn, user, signOut } = useAuth();
  const navigate = useNavigate();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut();
    navigate('/'); // Redirect to home after sign out
    handleMenuClose();
  };

  const handleSignInOtherAccount = () => {
    navigate("/SignInOptions");
    handleMenuClose();
  };

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#4A4A4A' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              alt="Eggccessories Logo"
              style={{ maxHeight: '50px', marginRight: '20px' }}
            />
            <Typography
              variant="h6"
              component={RouterLink}
              to="/"
              color="inherit"
              sx={{ textDecoration: 'none' }}
            >
              Eggccessories
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
            <Badge badgeContent={cartItemCount} color="secondary">
              <Button color="inherit" component={RouterLink} to="/cart">
                <ShoppingCart />
              </Button>
            </Badge>
            <Button color="inherit" component={RouterLink} to="/">Home</Button>
            <Button color="inherit" component={RouterLink} to="/productList">Catalog</Button>
            <Button color="inherit" component={RouterLink} to="/Inquiry">Inquiry</Button>
            {user ? (
              <>
                <Tooltip title={`Signed in as ${user.displayName || user.email}`} arrow>
                  <span>
                    <Button color="inherit" onClick={handleMenuClick}>Profile</Button>
                  </span>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleSignInOtherAccount}>Swap Accounts</MenuItem>
                  <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                </Menu>
              </>
            ) : (
              <Button color="inherit" component={RouterLink} to="/SignInOptions"> <AccountCircle/> </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
