import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Container, Box, CircularProgress, Button } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from './AuthContext';
import './Completion.css'; // Import the CSS file for animations

const stripePromise = loadStripe('pk_test_51PqgNQLrmUg65mhaYvsJ04Sl2fuWvaIseXTwLBQTj5EACkqQwJdefpr9haMEc5bBvUIZ77tvHDidXCGxrYrPWd0V006WxpHH59');

const Completion = () => {
  const [messageBody, setMessageBody] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // New state for errors
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      const queryParams = new URLSearchParams(location.search);
      const clientSecret = queryParams.get('payment_intent_client_secret');
      const paymentIntentId = queryParams.get('payment_intent');

      if (!clientSecret || !paymentIntentId) {
        setMessageBody('No payment information found.');
        setLoading(false);
        return;
      }

      try {
        const stripe = await stripePromise;
        const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

        if (error) {
          setError(error.message); // Set error message
          setMessageBody(null);
        } else {
          setMessageBody(
            <Typography variant="body1">
              Payment {paymentIntent.status}:
              <a
                href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {paymentIntent.id}
              </a>
            </Typography>
          );
          setError(null); // Clear any previous error
        }
      } catch (err) {
        setError(`An unexpected error occurred: ${err.message}`);
        setMessageBody(null);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentStatus();
  }, [location.search]);

  return (
    <Container sx={{ py: 5, textAlign: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="celebration-animation">
          🎉🎉🎉
        </div>
        <Typography variant="h3" component="h1" gutterBottom>
          {error ? 'Payment Failed!' : 'Payment Successful!'}
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ mt: 2 }}>
            {error ? (
              <Typography variant="body1" color="error" paragraph>
                {error}
              </Typography>
            ) : (
              <>
                <Typography variant="body1" paragraph>
                  {"Thank you for your purchase. Please check your email for confirmation."}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href="/"
                  sx={{
                    backgroundColor: "#36A49A",
                    "&:hover": {
                      backgroundColor: '#2f8f87',
                    },
                  }}
                >
                  Return to Home
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Completion;
